.logo{
    font-family: $playfairSC;
}

.posts{
    a{
        text-decoration: none;
        color: black;
        // transition: transform 250ms;
    }
    :hover{
        background-color: rgba(39, 39, 39, 0.829);
        color: white;
        text-decoration: none;
        // transform: translateY(-10px);
    }

    
}

.post-header{
    width: 100vw;
    
    img {
        float: left;
        width: 100%;
        max-height: 200px;
        object-fit: cover;
        overflow: hidden;
    }
}


@media (min-width: 320px) and (max-width: 480px) {
  
    /* CSS */
    .info-section {
        overflow: hidden;
        width: 85vw;
        margin: auto;
        
    }

    .info-col {
        float: left;
        width: 25%;
        background: #fff;
        text-align: center;
        height: 400px;
        overflow: hidden;
    }
    
    
  }