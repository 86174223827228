@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,800&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$backgroundColor: #ffffff;
$bodyColor: #000000;
// $bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$openSans: 'Open Sans', sans-serif;
$newsReader: 'Newsreader', serif;
$playfairSC: 'Playfair Display SC', serif;
$playfair: 'Playfair Display', serif;
$bodyFont: $openSans !important;

//* Breakpoints
$breakpoint-phone: 600px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 992px;
$breakpoint-lrg-desktop: 1200px;


// font-family: 'Newsreader', serif;
// font-family: 'Open Sans', sans-serif;
// font-family: 'Playfair Display', serif;
// font-family: 'Playfair Display SC', serif;

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0%;
  overflow-x: hidden;
}

@import "navigation";
@import "intro";




// @media (max-width: $breakpoint-phone) {
  
// }
// @media (min-width: $breakpoint-tablet) {
  
// }
// @media (min-width: $breakpoint-laptop) {
  
// }
// @media (min-width: $breakpoint-lrg-desktop) {
  
// }