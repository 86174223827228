nav{
    border-bottom: black 2px solid;
}

#navigation{
    // width: 99vw;
    // background-color: black;
    // color: white;
    // padding: 10px;
    // margin: 0;
    // border-bottom: #000000 2px solid;
    // height: 30px;
    // display: flex;
    // justify-content: space-between;

    .logoText{
        font-family: $newsReader;
    }

    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            display: inline;
            color:black;

            a{
                color:black;
                padding-left: 10px;
                text-decoration: none;
            }
        }

    }

    .topnav {
        overflow: hidden;
        background-color: #e9e9e9;
      }
      
      /* Style the links inside the navigation bar */
      .topnav a {
        float: left;
        display: block;
        color: black;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
      }
      
      /* Change the color of links on hover */
      .topnav a:hover {
        background-color: #ddd;
        color: black;
      }
      
      /* Style the "active" element to highlight the current page */
      .topnav a.active {
        background-color: #2196F3;
        color: white;
      }
      
      /* Style the search box inside the navigation bar */
      .topnav input[type=text] {
        float: right;
        padding: 6px;
        border: none;
        margin-top: 8px;
        margin-right: 16px;
        font-size: 17px;
      }
      
      /* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
      @media screen and (max-width: 600px) {
        .topnav a, .topnav input[type=text] {
          float: none;
          display: block;
          text-align: left;
          width: 100%;
          margin: 0;
          padding: 14px;
        }
        .topnav input[type=text] {
          border: 1px solid #ccc;
        }
      }
}


